import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import SEO from "../components/seo"

import ProductDescription from "../components/Product/ProductDescription"
import SimilarProducts from "../components/Product/SimilarProducts"

const Product = ({
  data,
  addVariantToCart,
  removeLineItemInCart,
  updateQuantityInCart,
  checkout,
}) => {
  const { shopifyProduct, allShopifyProduct } = data

  React.useEffect(() => {
    document.addEventListener("contextmenu", (e) => {
      if (e.target.tagName === "IMG") {
        e.preventDefault()
        e.stopPropagation()
      }
    })
  })

  return (
    <>
      <SEO path="/product-slug" title={shopifyProduct.title} />
      <ProductDescription
        shopifyProduct={shopifyProduct}
        addVariantToCart={addVariantToCart}
        removeLineItemInCart={removeLineItemInCart}
        updateQuantityInCart={updateQuantityInCart}
        checkout={checkout}
      />
      <SimilarProducts products={allShopifyProduct.edges.map((p) => p.node)} />
    </>
  )
}

export const postQuery = graphql`
  query ShopifyProductById($id: String!) {
    shopifyProduct(id: { eq: $id }) {
      id
      shopifyId
      handle
      title
      tags
      descriptionHtml
      publishedAt
      productType
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      variants {
        title
        shopifyId
        price
        priceV2 {
          amount
          currencyCode
        }
        selectedOptions {
          name
          value
        }
      }
      images {
        id
        originalSrc
      }
    }
    allShopifyProduct(limit: 4, filter: { id: { ne: $id } }) {
      edges {
        node {
          id
          shopifyId
          title
          handle
          publishedAt
          productType
          priceRange {
            minVariantPrice {
              amount
              currencyCode
            }
            maxVariantPrice {
              amount
              currencyCode
            }
          }
          images {
            id
            originalSrc
          }
        }
      }
    }
  }
`

Product.propTypes = {
  data: PropTypes.object.isRequired,
  addVariantToCart: PropTypes.func.isRequired,
  removeLineItemInCart: PropTypes.func.isRequired,
  updateQuantityInCart: PropTypes.func.isRequired,
  checkout: PropTypes.object.isRequired,
}

export default Product
