import React from "react"
import PropTypes from "prop-types"

import Link from "../../utils/link"

import "./SimilarProducts.scss"

const SimilarProducts = ({ products }) => {
  return (
    <section className="similar-products">
      <div className="similar-products__container">
        <div className="similar-products__products">
          {products.map((product) => (
            <Link
              to={`/shop/${product.handle}/`}
              className="similar-products__products__product-card"
              key={product.id}
            >
              <div className="similar-products__products__product-card__image">
                <picture>
                  <img
                    src={product.images[0].originalSrc}
                    alt={product.title}
                  />
                </picture>
              </div>
              <div className="similar-products__products__product-card__name">
                {product.title}
              </div>
              <div className="similar-products__horizontal-rule" />
              <div className="similar-products__products__product-card__product-type">
                {product.productType}
              </div>
              <div className="similar-products__products__product-card__price">
                $ {Number(product.priceRange.minVariantPrice.amount).toFixed(2)}{" "}
                - ${" "}
                {Number(product.priceRange.maxVariantPrice.amount).toFixed(2)}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  )
}

SimilarProducts.propTypes = {
  products: PropTypes.array.isRequired,
}

export default SimilarProducts
