import React from "react"
import PropTypes from "prop-types"
import { useSwipeable } from "react-swipeable"

import ProductDetails from "./ProductDetails"
import ShippingInformation from "./ShippingInformation"
import SizeGuide from "./SizeGuide"
import AfterPay from "../../assets/images/afterpay.svg"

import "./CollapsibleSection.scss"
import "./ProductDescription.scss"

const ProductDescription = ({ shopifyProduct, addVariantToCart }) => {
  const [selectedVariant, setSelectedVariant] = React.useState(
    shopifyProduct.variants[0]
  )
  const [quantity, setQuantity] = React.useState(1)
  const [selectedImageIndex, setSelectedImageIndex] = React.useState(1)
  const [sliderCount, setSliderCount] = React.useState(0)

  React.useEffect(() => {
    const length = shopifyProduct.images.length
    let index = 1
    let interval
    const timeout = setTimeout(() => {
      interval = setInterval(() => {
        if (index < length - 1) {
          index++
        } else {
          index = 1
        }
        setSelectedImageIndex(index)
      }, 4000)
    }, 5000)
    return () => {
      clearTimeout(timeout)
      clearInterval(interval)
    }
  }, [])

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      const nextImage = selectedImageIndex + 1
      if (nextImage < shopifyProduct.images.length) {
        setSelectedImageIndex(nextImage)
      } else {
        setSelectedImageIndex(1)
      }
    },
    onSwipedRight: () => {
      const nextImage = selectedImageIndex - 1
      if (nextImage > 1) {
        setSelectedImageIndex(nextImage)
      } else {
        setSelectedImageIndex(shopifyProduct.images.length - 1)
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })

  const increaseQuantity = () => {
    setQuantity(quantity + 1)
  }
  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1)
    }
  }

  const addToCart = () => {
    addVariantToCart(selectedVariant.shopifyId, quantity)
  }

  const sliderCountIncrease = () => {
    let count = sliderCount
    count++
    setSliderCount(count)
  }

  const sliderCountDecrease = () => {
    let count = sliderCount
    count--
    setSliderCount(count)
  }

  return (
    <section className="product-description">
      <div className="product-description__image" {...handlers}>
        <div className="product-description__image__bubbles">
          {shopifyProduct.images.map((image, index) => (
            <div key={index}>
              <span
                className={index === selectedImageIndex ? "selected" : ""}
                onClick={() => setSelectedImageIndex(index)}
              >
                <i className="fas fa-circle" />
              </span>
            </div>
          ))}
        </div>
        <div>
          <img
            src={shopifyProduct.images[selectedImageIndex].originalSrc}
            alt={shopifyProduct.title}
          />
        </div>
        <div
          className={
            sliderCount > 0
              ? "product-description__image__slider"
              : "display-none"
          }
        >
          {shopifyProduct.images.map((image, index) => {
            if (index < 1) {
              return null
            } else {
              return (
                <div key={index} onClick={() => setSelectedImageIndex(index)}>
                  <img
                    src={shopifyProduct.images[index].originalSrc}
                    alt={shopifyProduct.title}
                  />
                </div>
              )
            }
          })}
        </div>
      </div>
      <div className="product-description__details">
        <div className="product-description__details__heading">
          <div className="product-description__details__heading__wrapper">
            <div className="product-description__details__heading__title">
              {shopifyProduct.title}
            </div>
            <div className="product-description__details__heading__price">
              ${" "}
              {Number(shopifyProduct.priceRange.minVariantPrice.amount).toFixed(
                2
              )}{" "}
              - ${" "}
              {Number(shopifyProduct.priceRange.maxVariantPrice.amount).toFixed(
                2
              )}
            </div>
          </div>
        </div>
        <div
          className="product-description__details__description"
          dangerouslySetInnerHTML={{
            __html: shopifyProduct.descriptionHtml,
          }}
        />
        <div className="product-description__details__variants">
          { shopifyProduct.variants.length > 1 && 
            <div className="product-description__details__variants__title">
              { shopifyProduct.productType === 'Apparel' ? 'Size' : 'Artwork size' }
            </div>
          }
          <div className="product-description__details__variants__select">
            <div className="product-description__details__variants__list">
              {shopifyProduct.variants.length > 1 && shopifyProduct.variants.map((variant, index) => (
                <div
                  key={index}
                  className={`product-description__details__variants__list__variant ${
                    variant?.shopifyId === selectedVariant?.shopifyId
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => setSelectedVariant(variant)}
                >
                  <div className="product-description__details__variants__list__variant__title">
                    {variant.title}
                  </div>
                </div>
              ))}
            </div>
            <div className="product-description__details__variants__price">
              {selectedVariant && (
                <span>
                  $ {Number(selectedVariant.priceV2.amount).toFixed(2)}{" "}
                  {selectedVariant.priceV2.currencyCode}
                </span>
              )}
            </div>
          </div>
          <div className="product-description__afterpay">
            <img src={AfterPay} alt="AfterPay" />
            <span>Make 4 interest-free payments of  ${(Number(selectedVariant.priceV2.amount) * 0.25).toFixed(2)} fortnightly</span>
          </div>
        </div>
        <div className="product-description__details__quantity">
          <div className="product-description__details__quantity__container">
            <div className="product-description__details__quantity__select">
              <div className="product-description__details__quantity__select__text">
                Quantity
              </div>
              <div className="product-description__details__quantity__select__field">
                <button onClick={decreaseQuantity}>-</button>
                <span>{quantity}</span>
                <button onClick={increaseQuantity}>+</button>
              </div>
            </div>
            {selectedVariant !== null && (
              <button
                className="product-description__details__cart__button"
                onClick={addToCart}
              >
                Add to cart
              </button>
            )}
          </div>
        </div>

        <ProductDetails
          shopifyProduct={shopifyProduct}
          sliderCountIncrease={sliderCountIncrease}
          sliderCountDecrease={sliderCountDecrease}
        />
        <ShippingInformation
          shopifyProduct={shopifyProduct}
          sliderCountIncrease={sliderCountIncrease}
          sliderCountDecrease={sliderCountDecrease}
        />
        <SizeGuide
          shopifyProduct={shopifyProduct}
          variants={shopifyProduct.variants}
          sliderCountIncrease={sliderCountIncrease}
          sliderCountDecrease={sliderCountDecrease}
        />
      </div>
    </section>
  )
}

ProductDescription.propTypes = {
  shopifyProduct: PropTypes.object.isRequired,
  addVariantToCart: PropTypes.func.isRequired,
}

export default ProductDescription
