import React from "react"
import PropTypes from "prop-types"

import CaretUp from "../../assets/images/icons/caret-up.svg"
import CaretDown from "../../assets/images/icons/caret-down.svg"
import SizeGuideImage from "../../assets/images/icons/size-guide.svg"

import "./SizeGuide.scss"

const ArtGuide = () => (
  <div className="size-guide">
    <div className="size-guide__image">
      <img src={SizeGuideImage} alt="Size Guide" />
    </div>
    <div className="size-guide__table size-guide__table--art">
      <div className="size-guide__table__head">
        <div className="size-guide__table__head__column">Size</div>
        <div className="size-guide__table__head__column">Centimeters (CM)</div>
        <div className="size-guide__table__head__column">Inches (In)</div>
      </div>
      <div className="size-guide__table__row">
        <div className="size-guide__table__row__column">A5</div>
        <div className="size-guide__table__row__column">14.8 x 21.0 cm</div>
        <div className="size-guide__table__row__column">5.8 x 8.3 in</div>
      </div>
      <div className="size-guide__table__row">
        <div className="size-guide__table__row__column">A4</div>
        <div className="size-guide__table__row__column">21.0 x 29.7 cm</div>
        <div className="size-guide__table__row__column">8.3 x 11.6 in</div>
      </div>
      <div className="size-guide__table__row">
        <div className="size-guide__table__row__column">A3</div>
        <div className="size-guide__table__row__column">29.7 x 42.0 cm</div>
        <div className="size-guide__table__row__column">11.6 x 16.5 in</div>
      </div>
      <div className="size-guide__table__row">
        <div className="size-guide__table__row__column">A2</div>
        <div className="size-guide__table__row__column">42.0 x 59.4 cm</div>
        <div className="size-guide__table__row__column">16.5 x 23.3 in</div>
      </div>
      <div className="size-guide__table__row">
        <div className="size-guide__table__row__column">A1</div>
        <div className="size-guide__table__row__column">59.4 x 84.1 cm</div>
        <div className="size-guide__table__row__column">23.4 x 33.1 in</div>
      </div>
    </div>
  </div>
)

const ShirtGuide5001 = () => (
  <div className="size-guide">
    <div className="size-guide__table size-guide__table--apparel">
      <div className="size-guide__table__head">
        <div className="size-guide__table__head__column">Measurement</div>
        <div className="size-guide__table__head__column">Body Width (cm)</div>
        <div className="size-guide__table__head__column">Body Lenght (cm)</div>
      </div>

      <div className="size-guide__table__row">
        <div className="size-guide__table__row__column">SML</div>
        <div className="size-guide__table__row__column">47</div>
        <div className="size-guide__table__row__column">71</div>
      </div>
      <div className="size-guide__table__row">
        <div className="size-guide__table__row__column">MED</div>
        <div className="size-guide__table__row__column">52</div>
        <div className="size-guide__table__row__column">75</div>
      </div>
      <div className="size-guide__table__row">
        <div className="size-guide__table__row__column">LRG</div>
        <div className="size-guide__table__row__column">56.5</div>
        <div className="size-guide__table__row__column">78.5</div>
      </div>
      <div className="size-guide__table__row">
        <div className="size-guide__table__row__column">XLG</div>
        <div className="size-guide__table__row__column">61</div>
        <div className="size-guide__table__row__column">82</div>
      </div>
      <div className="size-guide__table__row">
        <div className="size-guide__table__row__column">2XL</div>
        <div className="size-guide__table__row__column">64</div>
        <div className="size-guide__table__row__column">83.5</div>
      </div>

      <div className="size-guide__table__row">
        <div className="size-guide__table__row__column">3XL</div>
        <div className="size-guide__table__row__column">68</div>
        <div className="size-guide__table__row__column">85</div>
      </div>
    </div>
  </div>
)

const ShirtGuide4002 = () => (
  <div className="size-guide">
    <div className="size-guide__table size-guide__table--apparel">
      <div className="size-guide__table__head">
        <div className="size-guide__table__head__column">Measurement</div>
        <div className="size-guide__table__head__column">Body Width (cm)</div>
        <div className="size-guide__table__head__column">Body Lenght (cm)</div>
      </div>
      <div className="size-guide__table__row">
        <div className="size-guide__table__row__column">XSM</div>
        <div className="size-guide__table__row__column">41.5</div>
        <div className="size-guide__table__row__column">67.5</div>
      </div>

      <div className="size-guide__table__row">
        <div className="size-guide__table__row__column">SML</div>
        <div className="size-guide__table__row__column">44</div>
        <div className="size-guide__table__row__column">68.5</div>
      </div>
      <div className="size-guide__table__row">
        <div className="size-guide__table__row__column">MED</div>
        <div className="size-guide__table__row__column">46.5</div>
        <div className="size-guide__table__row__column">69.5</div>
      </div>
      <div className="size-guide__table__row">
        <div className="size-guide__table__row__column">LRG</div>
        <div className="size-guide__table__row__column">49</div>
        <div className="size-guide__table__row__column">70.5</div>
      </div>
      <div className="size-guide__table__row">
        <div className="size-guide__table__row__column">XLG</div>
        <div className="size-guide__table__row__column">53</div>
        <div className="size-guide__table__row__column">71.5</div>
      </div>
      <div className="size-guide__table__row">
        <div className="size-guide__table__row__column">2XL</div>
        <div className="size-guide__table__row__column">56.5</div>
        <div className="size-guide__table__row__column">72.5</div>
      </div>
    </div>
  </div>
)

const ShirtGuide4001 = () => (
  <div className="size-guide">
    <div className="size-guide__table size-guide__table--apparel">
      <div className="size-guide__table__head">
        <div className="size-guide__table__head__column">Measurement</div>
        <div className="size-guide__table__head__column">Body Width (cm)</div>
        <div className="size-guide__table__head__column">Body Lenght (cm)</div>
      </div>
      <div className="size-guide__table__row">
        <div className="size-guide__table__row__column">XSM</div>
        <div className="size-guide__table__row__column">45.5</div>
        <div className="size-guide__table__row__column">63.5</div>
      </div>

      <div className="size-guide__table__row">
        <div className="size-guide__table__row__column">SML</div>
        <div className="size-guide__table__row__column">48</div>
        <div className="size-guide__table__row__column">64.5</div>
      </div>
      <div className="size-guide__table__row">
        <div className="size-guide__table__row__column">MED</div>
        <div className="size-guide__table__row__column">50.5</div>
        <div className="size-guide__table__row__column">65.5</div>
      </div>
      <div className="size-guide__table__row">
        <div className="size-guide__table__row__column">LRG</div>
        <div className="size-guide__table__row__column">53</div>
        <div className="size-guide__table__row__column">66.5</div>
      </div>
      <div className="size-guide__table__row">
        <div className="size-guide__table__row__column">XLG</div>
        <div className="size-guide__table__row__column">55.5</div>
        <div className="size-guide__table__row__column">67.5</div>
      </div>
      <div className="size-guide__table__row">
        <div className="size-guide__table__row__column">2XL</div>
        <div className="size-guide__table__row__column">58</div>
        <div className="size-guide__table__row__column">68.5</div>
      </div>
    </div>
  </div>

)

const SizeGuide = ({ shopifyProduct, sliderCountIncrease, sliderCountDecrease }) => {
  const [isExpanded, setIsExpanded] = React.useState(false)

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded)
    if (isExpanded) {
      sliderCountDecrease()
    } else {
      sliderCountIncrease()
    }
  }

  let Content = ArtGuide

  if (shopifyProduct.tags.includes("4001")) {
    Content = ShirtGuide4001
  } else if (shopifyProduct.tags.includes("4002")) {
    Content = ShirtGuide4002
  } else if (shopifyProduct.tags.includes("5001")) {
    Content = ShirtGuide5001
  }

  return (
    <section className="collapsible-section">
      <div className="collapsible-section__header" onClick={toggleIsExpanded}>
        <div className="collapsible-section__header__container">
          <div className="collapsible-section__header__title">Size Guide</div>
          <div className="collapsible-section__header__icon">
            {isExpanded ? <img src={CaretUp} /> : <img src={CaretDown} />}
          </div>
        </div>
      </div>
      {isExpanded && (
        <Content />
      )}
    </section>
  )
}

SizeGuide.propTypes = {
  sliderCountIncrease: PropTypes.func,
  sliderCountDecrease: PropTypes.func,
}

export default SizeGuide
