import React from "react"
import PropTypes from "prop-types"

import CaretUp from "../../assets/images/icons/caret-up.svg"
import CaretDown from "../../assets/images/icons/caret-down.svg"
import PaperIcon from "../../assets/images/icons/paper.svg"
import BrushIcon from "../../assets/images/icons/brush.svg"
import AustraliaIcon from "../../assets/images/icons/australia.svg"

import "./ProductDetails.scss"

const DefaultDetails = () => (
  <div className="product-details">
    <div className="product-details__head">
      <div className="product-details__head__column">
        <div className="product-details__head__column__image">
          <img src={PaperIcon} alt="Paper" />
        </div>
        <div className="product-details__head__column__details">
          Archival giclee print on 300gsm high quality uncoated stock
        </div>
      </div>
      <div className="product-details__head__column">
        <div className="product-details__head__column__image">
          <img src={BrushIcon} alt="Brush" />
        </div>
        <div className="product-details__head__column__details">
          All works individually hand signed and numbered by Jessica
        </div>
      </div>
      <div className="product-details__head__column">
        <div className="product-details__head__column__image">
          <img src={AustraliaIcon} alt="Australia" />
        </div>
        <div className="product-details__head__column__details">
          Hand printed and shipped from Melbourne, Australia
        </div>
      </div>
    </div>
    <div className="product-details__info">
      Your print will be packaged in a protective clear transparent
      plastic sleeve with a rigid white board behind to prevent it from
      bending. The prints will be placed in a rigid envelope to avoid
      damage during postage. For larger prints (A2 and A1) your print will
      be safely placed in a mailing tube. <br />
      <br />
      Please note: frames are not included and are used for illustrative
      purposes only. <br />
      <br />
      All illustrations and artworks © Jessica Coppet 2020
    </div>
  </div>
)

const ApparelDetails = () => (
  <div className="product-details">
    <div className="product-details__head"></div>
    <div className="product-details__info">
      100% Cotton<br />
      <br />
      Made to order<br />
      <br />
      Digitally printed in Australia.<br />
      <br />
      All illustrations and artworks © Jessica Coppet 2020
    </div>
  </div>
)

const ProductDetails = ({ shopifyProduct, sliderCountIncrease, sliderCountDecrease }) => {
  const [isExpanded, setIsExpanded] = React.useState(false)

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded)
    if (isExpanded) {
      sliderCountDecrease()
    } else {
      sliderCountIncrease()
    }
  }

  return (
    <section className="collapsible-section">
      <div className="collapsible-section__header" onClick={toggleIsExpanded}>
        <div className="collapsible-section__header__container">
          <div className="collapsible-section__header__title">
            Product Details
          </div>
          <div className="collapsible-section__header__icon">
            {isExpanded ? <img src={CaretUp} /> : <img src={CaretDown} />}
          </div>
        </div>
      </div>
      {isExpanded && (shopifyProduct.productType === 'Apparel' ? <ApparelDetails /> : <DefaultDetails />)}
    </section>
  )
}

ProductDetails.propTypes = {
  sliderCountIncrease: PropTypes.func,
  sliderCountDecrease: PropTypes.func,
}

export default ProductDetails
