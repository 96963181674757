import React from "react"
import PropTypes from "prop-types"

import CaretUp from "../../assets/images/icons/caret-up.svg"
import CaretDown from "../../assets/images/icons/caret-down.svg"

import "./ShippingInformation.scss"

const ShippingInformation = ({ shopifyProduct, sliderCountIncrease, sliderCountDecrease }) => {
  const [isExpanded, setIsExpanded] = React.useState(false)

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded)
    if (isExpanded) {
      sliderCountDecrease()
    } else {
      sliderCountIncrease()
    }
  }

  return (
    <section className="collapsible-section">
      <div className="collapsible-section__header" onClick={toggleIsExpanded}>
        <div className="collapsible-section__header__container">
          <div className="collapsible-section__header__title">
            Shipping Information
          </div>
          <div className="collapsible-section__header__icon">
            {isExpanded ? <img src={CaretUp} /> : <img src={CaretDown} />}
          </div>
        </div>
      </div>
      {isExpanded && (shopifyProduct.productType === 'Apparel' ?
        (
        <div className="shipping-information">
          <div className="shipping-information__container">
            <p>All T-shirts are sent via Australia Post with tracking number for a reliable and timely shipment.</p>
            <p>Shipping estimation is between 4 to 7 business days depending on your location.</p>
          </div>
        </div>
        )
        : (
        <div className="shipping-information">
          <div className="shipping-information__container">
            <p>Prints are carefully rolled, and shipped in kraft tube boxes.</p>
            <p>Each product is individually printed and assembled when you order it, so please allow 5 to 7 business days manufacture time for your prints.</p>
            <p>Depending on your location, we produce & ship your prints from one of our printing facilities in these countries: USA, Australia, UK.</p>
            <p>Shipping estimation is between 4 to 7 business days depending on your location.</p>
          </div>
        </div>
        ))
      }
    </section>
  )
}

ShippingInformation.propTypes = {
  sliderCountIncrease: PropTypes.func,
  sliderCountDecrease: PropTypes.func,
}

export default ShippingInformation
